import { ClientTemplate, Component } from '@/components/ClientTemplate';
import {
  Pages,
  SendResetPasswordCodePageTemplate,
  IdentifierRequestPayload,
  SendRpcRequestPayload,
} from '@/types';
import { isForm, mapFormToObject } from '@/helpers/dom';

@Component
export default class ClientResetPasswordLink extends ClientTemplate<Pages.SendResetPasswordCode> {
  successfullySent = false;

  identifier = '';

  async sendCode(element: Element) {
    this.error = null;
    if (isForm(element)) {
      const identifierPayload = mapFormToObject<IdentifierRequestPayload>(element);
      const payload: SendRpcRequestPayload = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_authorized: this.authorization.check(),
        ...identifierPayload,
      };
      const clientId = this.authorization.getClientId();
      const lang = this.store.getClientLang();
      if (clientId) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        payload.client_id = clientId;
      }
      if (lang) {
        payload.lang = lang;
      }
      const result = await this.pds.sendResetPasswordCode(payload);
      if (result.isFailure()) {
        this.error = result.error;
      } else {
        this.identifier = payload.identifier;
        this.successfullySent = true;
      }
    }
  }

  build() {
    const data: SendResetPasswordCodePageTemplate = {
      clientIsAuthorized: this.authorization.check(),
      successfullySent: this.successfullySent,
      resetPasswordLink: this.actions.create((element) => {
        this.sendCode(element);
      }),
      loginLink: this.actions.create(() => {
        this.router.goTo(Pages.Login);
      }),
      identifier: this.identifier,
    };
    return [Pages.SendResetPasswordCode, data] as const;
  }
}
